import {
	SYSTEM_LOCALE,
	LINK_TYPE_INTERNAL,
} from '@zyro-inc/site-modules/constants';

import { SiteData } from '@hostinger/builder-schema-validator';

import {
	computed,
	ref,
} from 'vue';

const website = ref<SiteData | null>(null);
const pageData = ref<any>({});
const isSiteEngine = ref(false);

export const useSiteGlobal = () => {
	const setWebsite = (payload: any) => {
		website.value = payload;
	};

	const setPageData = (payload: any) => {
		pageData.value = payload;
	};

	const setIsSiteEngine = (payload: boolean) => {
		isSiteEngine.value = payload;
	};

	const pages = computed(() => pageData.value.pages || {});
	const blocks = computed(() => pageData.value.blocks);
	const elements = computed(() => pageData.value.elements);
	const nav = computed(() => pageData.value.nav);
	const homePageId = computed(() => pageData.value.homePageId);
	const isNavHidden = computed(() => pageData.value.isNavHidden);
	const cookieBannerAcceptText = computed(() => pageData.value.cookieBannerAcceptText);
	const cookieBannerDisclaimer = computed(() => pageData.value.cookieBannerDisclaimer);
	const cookieBannerDeclineText = computed(() => pageData.value.cookieBannerDeclineText);
	const blogReadingTimeText = computed(() => pageData.value.blogReadingTimeText);
	const meta = computed(() => pageData.value.meta);
	const metaTitle = computed(() => pageData.value.metaTitle);
	const forms = computed(() => pageData.value.forms);
	const styles = computed(() => pageData.value.styles);
	const domain = computed(() => pageData.value.domain);
	const siteId = computed(() => pageData.value.siteId);
	const ecommerceShoppingCart = computed(() => pageData.value.ecommerceShoppingCart);
	const blogCategories = computed(() => pageData.value.blogCategories);
	const languageSwitcherLanguages = computed(() => pageData.value.languageSwitcherLanguages);
	const currentPageId = computed(() => pageData.value.currentPageId);
	const currentPageData = computed(() => pages.value[currentPageId.value]);
	const currentLocale = computed(() => pageData.value.currentLocale);
	const languageKeys = computed(() => pageData.value.languageKeys);

	const getPagePathFromId = ({ pageId }: any) => {
		if (!pages.value[pageId]) {
			return null;
		}

		const pageSlug = pages.value[pageId].slug;

		if ([
			SYSTEM_LOCALE,
			meta.value.defaultLocale,
		].includes(currentLocale.value)) {
			return homePageId.value === pageId ? '/' : `/${pageSlug}`;
		}

		return homePageId.value === pageId ? `/${currentLocale.value}` : `/${currentLocale.value}/${pageSlug}`;
	};

	const getButtonHref = ({
		isFormButton,
		linkedPageId,
		linkType,
		href,
	}: any) => {
		if (isFormButton) {
			return null;
		}

		// Maintenance mapper is required to clean up old website data
		// Where buttons with external links have linkType === 'internal'
		if (href) return href;

		if (linkType === LINK_TYPE_INTERNAL && linkedPageId) {
			return getPagePathFromId({
				pageId: linkedPageId,
			});
		}

		return href;
	};

	return {
		website,
		pageData,
		pages,
		blocks,
		elements,
		nav,
		homePageId,
		isNavHidden,
		cookieBannerAcceptText,
		cookieBannerDisclaimer,
		cookieBannerDeclineText,
		blogReadingTimeText,
		meta,
		metaTitle,
		forms,
		styles,
		domain,
		siteId,
		ecommerceShoppingCart,
		blogCategories,
		languageSwitcherLanguages,
		currentPageId,
		currentLocale,
		languageKeys,
		currentPageData,
		setWebsite,
		setPageData,
		getPagePathFromId,
		getButtonHref,
		setIsSiteEngine,
		isSiteEngine,
	};
};
