<script setup lang="ts">
import { getWebsiteCategoryUrl } from '@zyro-inc/site-modules/utils/ecommerce/category';
import { EcommerceCollection } from '@zyro-inc/site-modules/types';
import { useRoute } from 'vue-router';

interface Props {
	categories?: EcommerceCollection[],
	currentCategoryId: string,
	translations: Record<string, string>,
	isMobileView: boolean,
	isLinkDisabled: boolean,
	defaultCategory: {
		title: string,
		id: string,
	},
	blockId: string,
}
const props = withDefaults(defineProps<Props>(), {
	currentCategoryId: '',
	isMobileView: false,
	isLinkDisabled: false,
});

const emit = defineEmits<{
	'category-click': [string]
}>();
const route = useRoute();

const getCategoryUrl = (categoryId: string): string => {
	if (props.isLinkDisabled) {
		return route.fullPath;
	}

	return getWebsiteCategoryUrl(categoryId, props.blockId);
};

const handleCategoryClick = (categoryId: string): void => {
	emit('category-click', categoryId);
};
</script>

<template>
	<div class="category-list">
		<h2 class="category-list__title">
			{{ translations.browseBy }}
		</h2>
		<ul class="category-list__list">
			<li>
				<a
					class="category-list__link text-body"
					:href="getCategoryUrl('')"
					:class="{
						'text-body-small': isMobileView,
						'active': !currentCategoryId,
					}"
					@click.prevent="handleCategoryClick('')"
				>
					{{ defaultCategory.title }}
				</a>
			</li>
			<li
				v-for="category in categories"
				:key="category.id"
			>
				<a
					class="category-list__link text-body"
					:href="getCategoryUrl(category.id)"
					:class="{
						'text-body-small': isMobileView,
						'active': currentCategoryId === category.id,
					}"
					@click.prevent="handleCategoryClick(category.id)"
				>
					{{ category.title }}
				</a>
			</li>
		</ul>
		<div class="category-list__slot">
			<slot name="mobile" />
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/font-style";

.category-list {
	max-width: 200px;
	width: 100%;
	color: var(--body-color);

	&__title {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid;
		border-color: inherit;
	}

	&__list {
		list-style-type: none;

		li {
			margin-bottom: 8px;
		}
	}

	&__link {
		background-color: transparent;
		cursor: pointer;
		color: inherit;
		text-decoration: none;
		word-break: break-all;

		&:hover,
		&:focus,
		&.active{
			text-decoration: underline;
		}
	}

	&__slot {
		display: none;
	}
}

@include site-engine-mobile {
	.category-list {
		max-width: unset;
		color: var(--body-m-color, var(--body-color));
		display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
		gap: 8px;

		:deep(.product-list-sorting) {
			padding: 0;
		}

		&__title {
			display: none;
		}

		&__list {
			padding: 2px 0;
			width: auto;
			display: flex;
			flex-direction: row;
			overflow-x: auto;
			scrollbar-width: none;

			li {
				flex-shrink: 0;
				margin-bottom: 0;
			}
		}

		&__link {
			display: flex;
			padding: 4px 16px;
			border-radius: 16px;
			border: 1px solid;
			margin-right: 4px;
			border-color: hsl(0deg, 0%, 43%);
			text-decoration: none;

			&.text-body, &.text-body-small {
				color: var(--body-m-color, var(--body-color));
			}

			&:hover, &:focus, &.active {
				border-color: inherit;
				background-color: transparent;
			}

			&.active {
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					content: '';
					margin-right: 8px;
					margin-top: -4px;
					display: inline-block;
					transform: rotate(45deg);
					height: 8px;
					width: 5px;
					border-bottom: 1px solid var(--body-m-color, var(--body-color));
					border-right: 1px solid var(--body-m-color, var(--body-color));
				}
			}
		}

		&__slot {
			display: block;
			width: fit-content;
			margin-left: auto;
		}
	}
}
</style>
