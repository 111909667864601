<script setup lang="ts">
import ListSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ListSkeletonLoader.vue';
import ZyroPagination from '@zyro-inc/site-modules/components/ZyroPagination.vue';
import ProductListEmptyState from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListEmptyState.vue';
import ProductListItem from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListItem.vue';
import ProductSortDropdown from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductSortDropdown.vue';
import CategoryList from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/CategoryList.vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH } from '@zyro-inc/site-modules/constants/defaultStyles';
import {
	getFormattedBookingDuration,
	isProductPriceRangeShown,
	getFullProductQuantity,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { getLowestPriceVariant } from '@zyro-inc/site-modules/utils/ecommerce/productUtils';
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants';
import {
	ref,
	computed,
	nextTick,
	StyleValue,
} from 'vue';
import {
	EcommerceCollection,
	EcommerceProduct,
	EcommerceProductSorting,
	EcommerceProductVariantQuantity,
	EcommerceVariantPrice,
	ImageHoverEffect,
	SortingOptions,
	ImageRatioOption,
} from '@zyro-inc/site-modules/types';
import { useRoute } from 'vue-router';
import { ECOMMERCE_SORTING_VALUES } from '@zyro-inc/site-modules/constants/ecommerce';

const GAP_SIZE = 24;
const MAX_WIDTH_CATEGORY_LIST = 200;

interface Props {
	blockId: string;
	pageCount: number;
	currentPage: number;
	products: Array<EcommerceProduct> | [];
	productPages?: Record<string, any>;
	blockStyle?: Record<string, string>;
	textColorVars?: Record<string, string>;
	isProductListShown: boolean;
	isLoading?: boolean;
	isEager?: boolean;
	columnCount?: number;
	translations: Record<string, string>;
	productCategoryId?: string;
	isButtonEnabled?: boolean;
	buttonDisplay?: string;
	buttonText?: string;
	buttonStyle?: Record<string, string>;
	buttonType?: string;
	buttonBorderWidth?: number;
	ribbonStyle?: Record<string, string>;
	isProductListItemLinkDisabled?: boolean;
	siteId: string;
	variantsQuantity: Array<EcommerceProductVariantQuantity>;
	productSorting: EcommerceProductSorting;
	sorting?: typeof ECOMMERCE_SORTING_VALUES[keyof typeof ECOMMERCE_SORTING_VALUES];
	imageRatio?: ImageRatioOption;
	categories?: EcommerceCollection[];
	isCategoryListEnabled: boolean;
	backgroundColor?: string;
	isMobileView: boolean;
	imageHoverEffect?: ImageHoverEffect;
	isCategoryItemLinkDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isProductListShown: true,
	columnCount: 3,
	buttonType: 'primary',
	productPages: () => ({}),
	blockStyle: () => ({}),
	textColorVars: () => ({}),
	productCategoryId: '',
	buttonDisplay: '',
	buttonText: '',
	buttonStyle: () => ({}),
	buttonBorderWidth: 0,
	ribbonStyle: () => ({}),
	sorting: ECOMMERCE_SORTING_VALUES[SortingOptions.DEFAULT],
	productSorting: () => ({
		enabled: false,
	}),
	imageRatio: ImageRatioOption.COVER,
	isCategoryListEnabled: false,
	backgroundColor: 'var(--color-light)',
	isMobileView: false,
	imageHoverEffect: ImageHoverEffect.NO_EFFECT,
});

const emit = defineEmits<{
	'product-click': [EcommerceProduct],
	'button-click': [EcommerceProduct],
	'page-changed': [number],
	'sort-changed': [Event],
	'category-click': [string],
}>();

const route = useRoute();

const isAnimationActive = ref(false);
const customAttributes = computed(() => ({
	[DATA_ATTRIBUTE_ANIMATION_STATE]: isAnimationActive.value ? DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE : null,
}));
const emptyPageMessage = computed(() => props.translations.onlineStoreNoProducts || 'No publicly visible products');

const isCentered = computed(() => props.blockStyle?.textAlign === 'center');
const imageWidth = computed(() => {
	const totalGapsWidth = (props.columnCount - 1) * GAP_SIZE;
	const categoryListWidth = props.isCategoryListEnabled ? MAX_WIDTH_CATEGORY_LIST : 0;
	const listWidth = DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH - totalGapsWidth - categoryListWidth;

	return Math.floor(listWidth / props.columnCount);
});
const gapSize = computed(() => `${GAP_SIZE}px`);
const defaultCategory = computed(() => ({
	id: '',
	title: props.translations.allProducts || 'All products',
}));
const currentCategoryTitle = computed(() => {
	const category = props.categories?.find(({ id }) => id === props.productCategoryId);

	return category?.title || defaultCategory.value.title;
});
const computedStyles = computed(() => objectToCssVariables(props.textColorVars) as StyleValue);

const handlePageChange = async (page: number) => {
	// reset animation first so it would be re-triggered on page change
	isAnimationActive.value = false;
	emit('page-changed', page);

	await nextTick();

	isAnimationActive.value = true;

	const block = document.getElementById(props.blockId);
	const blockRect = block?.getBoundingClientRect();
	const isInViewport = blockRect && blockRect.top >= 0 && blockRect.bottom <= window.innerHeight;

	if (!isInViewport) {
		block?.scrollIntoView({
			behavior: 'smooth',
		});
	}
};

const getItemProductPage = (productId: string | number) => Object.values(props.productPages).find((page) => page.productId === productId);

const getItemProductPageTo = (productId: string | number) => {
	if (props.isProductListItemLinkDisabled) {
		return route;
	}

	const productPage = getItemProductPage(productId);

	if (!productPage) {
		return {
			path: '/',
		};
	}

	return {
		path: `/${productPage.slug}`,
	};
};

const getSmallestProductPrice = (product: EcommerceProduct): EcommerceVariantPrice => (
	isProductPriceRangeShown(product) ? getLowestPriceVariant(product).prices[0] : product.variants[0].prices[0]
);

const getProductImage = (product: EcommerceProduct): string | null => {
	if (!isProductPriceRangeShown(product)) {
		return product.thumbnail;
	}

	return getLowestPriceVariant(product).image_url || product.thumbnail;
};

const getSecondaryProductImage = (product: EcommerceProduct): string | null => product.images[1]?.url;
</script>

<template>
	<div
		:id="blockId"
		:style="computedStyles"
		class="block-product-list"
		:class="{ 'block-product-list--with-categories': isCategoryListEnabled }"
	>
		<div class="block-product-list__wrapper">
			<CategoryList
				v-if="!isLoading && isCategoryListEnabled"
				:translations="translations"
				:categories="categories"
				:current-category-id="productCategoryId"
				:is-mobile-view="isMobileView"
				:default-category="defaultCategory"
				:is-link-disabled="isCategoryItemLinkDisabled"
				:block-id="blockId"
				@category-click="emit('category-click', $event)"
			>
				<template #mobile>
					<ProductSortDropdown
						v-if="productSorting.enabled && productSorting.sortingOptions?.length"
						:product-sorting="productSorting"
						:sorting="sorting"
						:translations="translations"
						@sort-changed="emit('sort-changed', $event)"
					/>
				</template>
			</CategoryList>
			<div class="block-product-list__content-container">
				<ListSkeletonLoader
					v-if="isLoading"
					:column-count="columnCount"
					:image-ratio="imageRatio"
					:is-category-list-enabled="isCategoryListEnabled"
				/>
				<div
					v-else-if="isProductListShown"
					style="width: 100%;"
				>
					<h3
						v-if="isCategoryListEnabled"
						class="block-product-list__category"
					>
						{{ currentCategoryTitle }}
					</h3>
					<div
						ref="productList"
						class="product-list"
					>
						<ProductSortDropdown
							v-if="productSorting.enabled && productSorting.sortingOptions?.length"
							:product-sorting="productSorting"
							:sorting="sorting"
							:translations="translations"
							class="product-list__sort"
							:class="{ 'product-list__sort--hidden-mobile': isCategoryListEnabled }"
							@sort-changed="emit('sort-changed', $event)"
						/>
						<div class="product-list__content">
							<RouterLink
								v-for="(product, index) in products"
								:key="`product-${index}-${product.id}`"
								:to="getItemProductPageTo(product.id)"
								class="product-list__link"
							>
								<ProductListItem
									v-bind="customAttributes"
									:image="getProductImage(product)"
									:secondary-image="getSecondaryProductImage(product)"
									:title="product.title"
									:ribbon="product.ribbon_text"
									:price="getSmallestProductPrice(product)"
									:is-centered="isCentered"
									:is-eager="isEager && index === 0"
									:duration="getFormattedBookingDuration(product, translations)"
									:image-width="imageWidth"
									:image-ratio="imageRatio"
									:image-hover-effect="imageHoverEffect"
									:is-store-quantity-tracked="product.variants[0].manage_inventory"
									:is-price-range-shown="isProductPriceRangeShown(product)"
									:quantity="getFullProductQuantity({
										product,
										variantsQuantity
									})"
									:product-type="product.type.value"
									:translations="translations"
									:is-mobile-view="isMobileView"
									:is-button-enabled="isButtonEnabled"
									:button-display="buttonDisplay"
									:button-text="buttonText"
									:button-style="buttonStyle"
									:button-type="buttonType"
									:button-border-width="buttonBorderWidth"
									:ribbon-style="ribbonStyle"
									:is-purchasable="product.purchasable ?? true"
									:site-id="siteId"
									@click="$emit('product-click', product)"
									@button-click="$emit('button-click', product)"
								/>
							</RouterLink>
						</div>
						<ZyroPagination
							:current-page="currentPage"
							:page-count="pageCount"
							class="product-list__pagination"
							color="var(--body-color)"
							@change-page="handlePageChange($event)"
						/>
					</div>
				</div>
				<div
					v-else
					class="block-product-list__empty-state"
				>
					<h3
						v-if="isCategoryListEnabled"
						class="block-product-list__category"
					>
						{{ currentCategoryTitle }}
					</h3>
					<ProductListEmptyState
						:text-color-vars="textColorVars"
						:empty-page-message="emptyPageMessage"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@include font-style("h6", "h2", ".block-product-list");
@include font-style("h3", "h3", ".block-product-list");
@include font-style("body", ".text-body", ".block-product-list");
@include font-style("body-small", ".text-body-small", ".block-product-list");

@mixin product-list-mobile($columns) {
	.block-product-list {
		padding: var(--m-block-padding);
	}

	.product-list {
		&__content {
			grid-template-columns: repeat($columns, 1fr);
		}

		&__sort {
			&--hidden-mobile {
				display: none;
			}
		}
	}
}

.block-product-list {
	z-index: $z-index-site-engine-block-grid;
	padding: var(--block-padding);

	&--with-categories {
		.product-list-container {
			justify-content: flex-start;
		}
	}

	&__wrapper {
		max-width: var(--content-width);
		display: flex;
		width: 100%;
		margin: 0 auto;
		gap: 40px;
	}

	&__content-container {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&__category {
		margin-bottom: 32px;
	}

	&__empty-state {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}

.product-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&__link {
		width: 100%;
		text-decoration: none;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(v-bind(columnCount), 1fr);
		gap: v-bind(gapSize);
		width: 100%;
	}

	&__pagination {
		margin-top: 16px;

		:deep(.pagination__trigger--current),
		:deep(button:hover),
		:deep(button:focus) {
			color: var(--body-m-color, var(--body-color));
			text-decoration: underline;
			filter: brightness(0.8)
		}
	}
}

@include site-engine-mobile {
	@include product-list-mobile(1);

	.block-product-list {
		&__wrapper {
			flex-direction: column;
			gap: 16px;
		}

		&__category {
			display: none;
		}
	}
}

@media screen and (min-width: 601px) and (max-width: $media-mobile) {
	@include product-list-mobile(2);
}
</style>
