export const MEDIA_MOBILE_BREAKPOINT = 920; // Grid/flex change point. Corresponds to global-styles/abstracts/_variables.scss
export const MEDIA_MOBILE_SCREEN_BREAKPOINT = 760; // matches $media-mobile SCSS variable

export const SEO_PAGE_MIN_TEXT_LENGTH = 301;
export const SEO_MAX_STRING_LENGTH_DESCRIPTION = 200;
export const SEO_MIN_STRING_LENGTH_DESCRIPTION = 156;
export const SEO_MAX_STRING_LENGTH_TITLE = 60;
export const SEO_MIN_STRING_LENGTH_TITLE = 10;

export const SYSTEM_LOCALE = 'system';

export const GENERATED_SITE_TEMPLATE_ID = 'generated';
// Is needed for old legacy website types
export const IMPORTED_SITE_TEMPLATE_ID = 'website-importer';
export const BLANK_LAYOUT_TEMPLATE_ID = 'blanklayout';
export const AI_GENERATED_TEMPLATE_ID = 'aigenerated';

export const NAVIGATION_LINK_TYPE = 'Link';
export const NAVIGATION_PAGE_TYPE = 'Page';
export const NAVIGATION_FOLDER_TYPE = 'Folder';
export const NAVIGATION_HOMEPAGE_TYPE = 'Homepage';

export const NAVIGATION_GROUP_ROOT = 'ROOT';
export const NAVIGATION_GROUP_HIDDEN = 'HIDDEN';

export const LINK_TYPE_INTERNAL = 'internal';
export const LINK_TYPE_EXTERNAL = 'external';
export const LINK_TYPE_DOWNLOAD = 'download';
export const LINK_TYPE_ANCHORED_SECTION = 'anchored-section';

export const STATIC_ASSETS_BASE_PATH = 'https://static.zyro.com';
export const FLAG_CDN_PREFIX = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/flags/4x3';

export const BUILDER_CLASS = 'builder';
export const SITE_PREVIEW_CLASS = 'site-preview';
export const BUILDER_BOTTOM_CLASS = 'builder__bottom';
export const BLOCK_HEADER_CLASS = 'block-header';
export const TOP_BLOCK_CLASS = 'top-blocks';
export const TOP_BLOCK_STICKY_CLASS = 'top-blocks--sticky';

export const TABS = {
	SEO: 'seo',
	GENERAL: 'general',
	SOCIAL_IMAGE: 'socialImage',
	PASSWORD_PROTECTION: 'passwordProtection',
	ECOMMERCE: 'ecommerce',
} as const;

export type TabsType = (typeof TABS)[keyof typeof TABS];

export const PAGE_ID_PRIVATE = 'private';

// Page password page design types
export const PAGE_PASSWORD_DESIGN_TYPE_DEFAULT = 'default';
export const PAGE_PASSWORD_DESIGN_TYPE_CALM = 'calm';
export const PAGE_PASSWORD_DESIGN_TYPE_POPULAR = 'popular';

// Page types
export const PAGE_TYPE_DEFAULT = 'default';
export const PAGE_TYPE_BLOG_LIST = 'blogList';
export const PAGE_TYPE_BLOG = 'blog';
export const PAGE_TYPE_ECOMMERCE = 'ecommerce';
export const PAGE_TYPE_ECOMMERCE_PRODUCT = 'ecommerce-product';
export const PAGE_TYPE_PRIVATE = 'private';

// Store types
export const META_ECOMMERCE_TYPE = 'ecommerceType';
export const ECOMMERCE_TYPE_ZYRO = 'zyro';

// Block slots
export const BLOCK_SLOT_FOOTER = 'footer';
export const PROPERTY_FOOTER_SLOT_IS_HIDDEN = 'footerSlotIsHidden';

// Block types
export const BLOCK_TYPE_NAVIGATION = 'BlockNavigation';
export const BLOCK_TYPE_LAYOUT = 'BlockLayout';
export const BLOCK_TYPE_BLOG_HEADER = 'BlockBlogHeader';
export const BLOCK_TYPE_BLOG_LIST = 'BlockBlogList';
export const BLOCK_TYPE_IMAGE_SLIDESHOW = 'BlockImageSlideshow';
export const BLOCK_TYPE_ECOMMERCE_PRODUCT = 'BlockEcommerceProduct';
export const BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST = 'BlockEcommerceProductList';
export const BLOCK_TYPE_STICKY_BAR = 'BlockStickyBar';

// Block data
export const BLOCK_BACKGROUND_TYPE_IMAGE = 'image';
export const BLOCK_STICKY_BAR_ID = 'stickyBar';

// Element types
export const ELEMENT_TYPE_BUTTON = 'GridButton';
export const ELEMENT_TYPE_STRIPE_BUTTON = 'GridStripeButton';
export const ELEMENT_TYPE_ECOMMERCE_BUTTON = 'GridEcommerceButton';
export const ELEMENT_TYPE_MAP = 'GridMap';
export const ELEMENT_TYPE_VIDEO = 'GridVideo';
export const ELEMENT_TYPE_IMAGE = 'GridImage';
export const ELEMENT_TYPE_TEXT_BOX = 'GridTextBox';
export const ELEMENT_TYPE_FORM = 'GridForm';
export const ELEMENT_TYPE_INSTAGRAM_FEED = 'GridInstagramFeed';
export const ELEMENT_TYPE_SOCIAL_ICONS = 'GridSocialIcons';
export const ELEMENT_TYPE_GALLERY = 'GridGallery';
export const ELEMENT_TYPE_EMBED = 'GridEmbed';
export const ELEMENT_TYPE_SHAPE = 'GridShape';
export const ELEMENT_TYPE_SEARCH_BAR = 'SearchBar';

export const TARGET_SELF = '_self';
export const TARGET_BLANK = '_blank';
export const REL_NOFOLLOW = 'nofollow';
export const ANCHOR_TAG = 'a';
export const DIV_TAG = 'div';

export const IMAGE_CLICK_ACTION_LIGHTBOX = 'lightbox';
export const IMAGE_CLICK_ACTION_LINK = 'link';
export const IMAGE_CLICK_ACTION_NONE = 'none';

export const DEFAULT_HTML_LANG_VALUE = {
	title: 'English',
	value: 'en',
};

export const ELEMENT_POSITION_KEY_MOBILE = 'mobile';
export const ELEMENT_POSITION_KEY_DESKTOP = 'desktop';

export const RESIZABLE_WIDTH_ELEMENT_TYPES = [
	ELEMENT_TYPE_IMAGE,
	ELEMENT_TYPE_MAP,
	ELEMENT_TYPE_VIDEO,
	ELEMENT_TYPE_TEXT_BOX,
	ELEMENT_TYPE_GALLERY,
	ELEMENT_TYPE_INSTAGRAM_FEED,
	ELEMENT_TYPE_EMBED,
	ELEMENT_TYPE_FORM,
	ELEMENT_TYPE_SOCIAL_ICONS,
	ELEMENT_TYPE_SHAPE,
	ELEMENT_TYPE_SEARCH_BAR,
];

export const RESIZABLE_HEIGHT_ELEMENT_TYPES = [
	ELEMENT_TYPE_IMAGE,
	ELEMENT_TYPE_MAP,
	ELEMENT_TYPE_VIDEO,
	ELEMENT_TYPE_EMBED,
	ELEMENT_TYPE_SHAPE,
	ELEMENT_TYPE_BUTTON,
	ELEMENT_TYPE_BUTTON,
	ELEMENT_TYPE_ECOMMERCE_BUTTON,
];

export const DESKTOP_BLOCK_WIDTH = 1224;
export const BLOCK_STICKY_BAR_MAX_HEIGHT = 100;
export const BLOCK_STICKY_BAR_MIN_HEIGHT = 40;

export const DEFAULT_SECTION_ROW_HEIGHT = 24;
export const DEFAULT_SECTION_ROW_GAP = 16;
export const DEFAULT_SNAP_TO_ELEMENTS = true;
export const DEFAULT_SNAP_TO_GUIDES = true;
export const DRAG_SNAP_THRESHOLD = 5;

export const DEFAULT_SNAPPING_PROPERTIES_MAP = {
	snapRowHeight: DEFAULT_SECTION_ROW_HEIGHT,
	snapRowGap: DEFAULT_SECTION_ROW_GAP,
	shouldSnapToElements: DEFAULT_SNAP_TO_ELEMENTS,
	shouldSnapToGuides: DEFAULT_SNAP_TO_GUIDES,
};

// animations
export const ANIMATION_TYPE_GLOBAL = 'global';
export const ANIMATION_TYPE_ELEMENT = 'element';

export const ANIMATION_APPLICABLE_BLOCK_TYPES = [
	BLOCK_TYPE_BLOG_LIST,
	BLOCK_TYPE_BLOG_HEADER,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_STICKY_BAR,
];
export const ANIMATION_ROLE_ITEMS = [
	ELEMENT_TYPE_IMAGE,
	ELEMENT_TYPE_GALLERY,
	ELEMENT_TYPE_INSTAGRAM_FEED,
	...ANIMATION_APPLICABLE_BLOCK_TYPES,
];
export const ANIMATION_NOT_SUPPORTED_ELEMENTS = [ELEMENT_TYPE_EMBED];

export const DATA_ATTRIBUTE_ANIMATION_ROLE = 'data-animation-role';
export const DATA_ATTRIBUTE_ANIMATION_ROLE_IMAGE = 'image';
export const DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT = 'block-element';
export const DATA_ATTRIBUTE_ANIMATION_STATE = 'data-animation-state';
export const DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE = 'active';
export const DATA_ATTRIBUTE_ELEMENT_ID = 'data-element-id';

export const ELEMENT_DATA_ATTRIBUTE = 'data-el-id';

export const BODY_ELEMENTS_BY_ELEMENT_ID = [
	'noscript-gtm',
	'fb-messenger',
];

export const DEFAULT_MIN_READ_TEXT = 'min read';

export const THEME_ERROR = 'error';
export const THEME_SUCCESS = 'success';
export const THEME_WARNING = 'warning';
export const THEME_INFO = 'info';
export const THEME_LOGO = 'logo';

export const AVAILABLE_TOAST_THEMES = [
	THEME_ERROR,
	THEME_SUCCESS,
	THEME_WARNING,
	THEME_INFO,
	THEME_LOGO,
] as const;

export type ToastType = typeof AVAILABLE_TOAST_THEMES[number];

export const DATA_ATTRIBUTE_SELECTOR = 'data-selector';
export const DATA_ATTRIBUTE_SELECTOR_GRID_RESIZER = 'data-grid-resizer';
export const DATA_ATTRIBUTE_SELECTOR_MODAL_BACKDROP = 'data-modal-backdrop';
export const DATA_ATTRIBUTE_SELECTOR_DRAWER_TRIGGER = 'data-drawer-trigger';
export const DATA_ATTRIBUTE_SELECTOR_TOAST = 'data-toast';
export const DATA_ATTRIBUTE_SELECTOR_SUBMISSIONS_EXPORT = 'data-submission-export';
export const DATA_ATTRIBUTE_SELECTOR_POPUP_CONTENT = 'data-popup-content';
export const DATA_ATTRIBUTE_SELECTOR_TOOLTIP_CONTENT = 'data-tooltip-content';
export const DATA_ATTRIBUTE_SELECTOR_CONTEXT_MENU = 'data-context-menu';
export const DATA_ATTRIBUTE_SELECTOR_ASSET_MANAGER = 'data-asset-manager';
export const DATA_ATTRIBUTE_SELECTOR_HEATMAP = 'data-heatmap-header';
export const DATA_ATTRIBUTE_SELECTOR_RESIZE_DOTS = 'data-resize-dots';
export const DATA_ATTRIBUTE_SELECTOR_IMAGE = 'data-image';
export const DATA_ATTRIBUTE_SELECTOR_MODAL_OVERLAY = 'data-overlay-modal';
export const DATA_ATTRIBUTE_SELECTOR_FONT_SELECT = 'data-font-select';
export const DATA_ATTRIBUTE_SELECTOR_MODAL_CONTENT = 'data-modal';
export const DATA_ATTRIBUTE_SELECTOR_LANGUAGE_DELETE = 'data-language-delete';

export const PREVIEW_SUBDOMAINS = [
	'.builder-preview.space',
	'.builder-preview.com',
];

export const BROWSER_NAME_SAFARI = 'Safari';
export const SEARCH_INDICE_PRODUCTS = 'products';
export const Z_INDEX_FORCED_ON_TOP_ELEMENT = 1000;

export const PRODUCT_IMAGE_MAX_WIDTH_PX_DEFAULT = 600;
export const PRODUCT_IMAGE_MAX_WIDTH_PX_LEFT = 528;
export const PRODUCT_IMAGE_MAX_WIDTH_PX_PREVIEW = 400;
export const PRODUCT_IMAGE_MAX_WIDTH_SMALL_PX = 64;

export const QR_IMAGE_FORMATS = {
	PNG: {
		name: 'PNG',
	},
	SVG: {
		name: 'SVG',
	},
} as const;

export const HEX_TRANSPARENT_BACKGROUND = '#00000000';

export const QR_CODE_LOCATION_TYPES = {
	PAGES: 'pages and navigation',
	STORE: 'online store',
	BLOG: 'blog',
	PUBLISH: 'publish modal',
} as const;

export const BLOCK_HEADER_KEY_DESKTOP_LAYOUT = 'headerLayout';
export const BLOCK_HEADER_KEY_MOBILE_LAYOUT = 'mHeaderLayout';

export const BLOCK_HEADER_LAYOUTS = {
	MOBILE_1: 'mobile-1',
	MOBILE_2: 'mobile-2',
	MOBILE_3: 'mobile-3',
	DESKTOP_1: 'desktop-1',
	DESKTOP_2: 'desktop-2',
	DESKTOP_3: 'desktop-3',
	DESKTOP_4: 'desktop-4',
	DESKTOP_5: 'desktop-5',
} as const;

export const BACKGROUND_TYPES = {
	IMAGE: 'image',
	COLOR: 'color',
	GRADIENT: 'gradient',
	VIDEO: 'video',
} as const;
